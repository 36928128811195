@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

/* You can add global styles to this file, and also import other style files */
:root {
  /* Colors: */
  --white: #FFFFFF;
  --nearly-white: #F9FBFF;
  --light-blue: #F5F7FE;
  --psuedo-black: #363E42;
  --muted-psuedo-black: #2F3B4080;
  --worxs-blue: #1E4784;
  --worxs-light-blue: #6FC2D5;
  --worxs-green: #B9CE00;
  --worxs-red: #F28D6E;
}

/*@media (prefers-color-scheme: dark) {*/
/*  :root {*/
/*    --white: #2d3438;*/
/*    --nearly-white: #2F3B40;*/
/*    --light-blue: #1E4784;*/
/*    --psuedo-black: #F9FBFF;*/
/*    --muted-psuedo-black: #F9FBFF80;*/
/*    --worxs-blue: #1E4784;*/
/*    --worxs-light-blue: #6FC2D5;*/
/*    --worxs-green: #B9CE00;*/
/*    --worxs-red: #F28D6E;*/
/*  }*/
/*}*/

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/ProximaNova-Bold.woff2') format('woff2'),
  url('/assets/fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/ProximaNova-Semibold.woff2') format('woff2'),
  url('/assets/fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
  url('/assets/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  background-color: var(--white);
  color: var(--psuedo-black);
  font-family: Proxima Nova, sans-serif;
}


/* Headers */

.h-lg {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2rem;
}

.h-md {
  font-size: 28px;
  font-weight: bold;
  line-height: 40px;
}

.h-sm {
  font-size: 24px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 0;
}

.strapline {
  color: var(--worxs-light-blue);
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.95px;
  line-height: 16px;
  text-transform: uppercase;
}

@media screen and (min-width:992px) {
  .h-lg {
    font-size: 3rem;
    line-height: 3rem;
  }
}


/* Buttons */

.btn {
  border: none;
  border-radius: 30px;
  height: 3em;
  font-size: 16px;
  line-height: calc(3em - 16px);
  text-align: left;
  padding-left: 25px;
  padding-right: 25px;
}

.btn.btn-md {
  line-height: calc(3em - 16px);
  height: 3em;
}

.btn.btn-sm {
  height: 2.5em;
}

.btn.btn-lg {
  border-radius: 50px;
  height: 3.5em;
  line-height: calc(3.5em - 18px);
  font-size: 18px;
}

.btn.btn-circle {
  width: 55px;
  height: 55px;
}

.btn.btn-primary {
  background-color: var(--worxs-green);
  color: var(--white);
}

.btn.btn-primary:active {
  background-color: #849b34;
}

.btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #849b34;
  color: var(--white);
}

.btn.btn-secondary {
  background-color: var(--worxs-blue);
  color: var(--white);
}

.btn.btn-outline-secondary {
  --bs-btn-color: var(--worxs-blue);
  --bs-btn-hover-color: var(--light-blue);
  --bs-btn-border-color: var(--worxs-blue);
  --bs-btn-hover-bg: var(--worxs-blue);
  --bs-btn-hover-border-color: var(--light-blue);
  border: 2px solid;
}

.btn.btn-light {
  background-color: var(--white);
  color: var(--psuedo-black);
}

a {
  color: var(--worxs-blue);
  font-weight: bold;
}


/* Donuts */

.donut {
  width: 11px;
  height: 11px;
  border: 3px solid var(--psuedo-black);
  border-radius: 11px;
  display: inline-block;
}

.donut.donut-lg {
  width: 20px;
  height: 20px;
  border: 5px solid var(--psuedo-black);
}

.donut.donut-blue {
  border-color: var(--worxs-blue);
}

.donut.donut-light-blue {
  border-color: var(--worxs-light-blue);
}

.donut.donut-green {
  border-color: var(--worxs-green);
}

.donut.donut-red {
  border-color: var(--worxs-red);
}

/* Floating labels -- deprecated, should be replaced with bootstrap5's input-floating -- */
.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input,
.form-label-group label {
  height: 3.125rem;
  padding: .75rem;
}

.form-label-group label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:-moz-placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}

.form-label-group input:not(:-ms-input-placeholder) {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}

.form-label-group input:not(:-moz-placeholder-shown) ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777;
}

.form-label-group input:not(:-ms-input-placeholder) ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777;
}

.form-label-group input:-webkit-autofill ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777;
}

div:has(input[aria-required="true"]) > label:after {
  content: " *";
  color: var(--bs-danger);
  font-weight: bold;
}


/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .form-label-group label {
    position: static;
  }

  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

i.right-arrow {
  background-image: url('/assets/images/right-arrow.svg');
  width: 16px;
  height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

i.left-arrow {
  background-image: url('/assets/images/left-arrow.svg');
  width: 16px;
  height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

a.left-arrow::before {
  content: " ";
  display: inline-block;
  background-image: url('/assets/images/left-arrow.svg');
  width: 17px;
  height: 11px;
  margin-right: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-secondary i.right-arrow,
.btn-secondary i.left-arrow,
.btn-primary i.right-arrow,
.btn-primary i.left-arrow
{
  filter: invert(100%);
}

.btn i.right-arrow, .btn i.left-arrow  {
  display: inline-block;
  margin-bottom: -3px;
}

.btn.btn-lg i.right-arrow, .btn.btn-lg i.left-arrow {
  margin-bottom: -4px;
}

.btn i.right-arrow {
  margin-left: 5px;
}

.btn i.left-arrow {
  margin-right: 5px;
}

.btn.btn-circle i.right-arrow, .btn.btn-circle i.left-arrow {
  margin-left: -4px;
  display: block;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
